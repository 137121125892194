import { getPriceModifyRecord as getList } from '@/api/product/productApi'
import { checkPermission } from '@/utils'

export const permission = {
  editPrice: checkPermission('externaladmin:product:maintain:priceManage:editPrice'),
  editGears: checkPermission('externaladmin:product:maintain:priceManage:editGears'),
  importPrice: checkPermission('externaladmin:product:maintain:priceManage:importPrice'),
  exportPrice: checkPermission('externaladmin:product:maintain:priceManage:exportPrice')
}

export const historyOption = {
  getList,
  getPostData(row) {
    return {
      prototypeId: row.parentId,
      orderItems: [
        {
          column: 'create_time',
          asc: false
        }
      ]
    }
  },
  menuText: '查看价格',
  editBtn: false,
  delBtn: false,
  topPage: false,
  column: [
    {
      label: '编辑时间',
      prop: 'modifyTime'
    },
    {
      label: '档位分类',
      prop: 'levelCategoryId',
      type: 'select',
      dicType: 'gearsCategory'
    }
  ]
}
export const viewPricesOption = {
  topPage: false,
  page: false,
  menu: false,
  column: [
    {
      label: '尺码',
      prop: 'sizeName'
    },
    {
      label: '最低售价',
      prop: 'sizeLimitPrice'
    }
  ]
}
